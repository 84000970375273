import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import {
  IconButton,
  TextField,
} from '../components/ui-shared';

import accountServices, { ResetPasswordModel } from '../services/account';

type ChangePasswordState = {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPassword(): JSX.Element {
  const { resetPasswordToken }: never = useParams();
  const history = useHistory();
  const [state, setState] = React.useState<ChangePasswordState>({
    newPassword: '',
    confirmPassword: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    setIsError(false);
    setErrorMessage('');
    if (state.newPassword !== state.confirmPassword) {
      setIsError(true);
      setErrorMessage('Xác nhận mật khẩu không chính xác!');
    }
    else {
      setIsLoading(true);
      try {
        const model: ResetPasswordModel = {
          token: resetPasswordToken,
          newPassword: state.newPassword,
        };
        await accountServices.resetPassword(model);
        setIsSuccess(true);
        setIsLoading(false);
      }
      catch {
        setIsError(true);
        setErrorMessage('Không thể đặt lại mật khẩu. Vui lòng thử lại sau!');
        setIsLoading(false);
      }
    }
  }

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Đặt lại mật khẩu
      </Typography>
          {isSuccess && (
            <>
              <Typography variant="body1">
                Đặt lại mật khẩu thành công. Nhấn vào nút bên dưới để đến trang đăng nhập.
            </Typography>
              <IconButton
                variant="contained"
                color="primary"
                text="Đến trang đăng nhập"
                icon="sign-in-alt"
                onClick={() => { history.push('/login'); }}
              />
            </>
          )}
        </Grid>
        {!isSuccess && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                label="Mật khẩu mới"
                name="newPassword"
                type="password"
                value={state.newPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Xác nhận mật khẩu"
                name="confirmPassword"
                type="password"
                value={state.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
            {isLoading
              ? <Grid item xs={12}><LinearProgress /></Grid>
              : (
                <Grid container item xs={12} spacing={2}>
                  {isError && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="subtitle2">
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      text="Đặt lại mật khẩu"
                      icon="sync-alt"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
