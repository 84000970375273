import axios from 'axios';

const apiUrl = '/api/packages';

export type PackageModel = {
  code: string;
  name: string;
  begin: Date;
  end: Date;
  status: number;
  description: string;
  items: ItemModel[];
  attachments: AttachmentModel[];
}

export type ItemModel = {
  id: number;
  no: number;
  code: string;
  description: string;
  unit: string;
  optionUnit: string;
  quantity: number;
  optionQuantity: number;
  spec: string;
  note: string;
  begin: Date;
  end: Date;
  price: string;
}

export type AttachmentModel = {
  id: number;
  fileName: string;
}

export type CreateQuotationModel = {
  items: CreateQuotationItemModel[],
  files: File[],
}

export type CreateQuotationItemModel = {
  itemId: string;
  price: string;
}

export type QuotationModel = {
  submitted: boolean,
  items: QuotationItemModel[],
  attachments: QuotationAttachmentModel[],
}

export type QuotationItemModel = {
  itemId: number;
  price: number;
}

export type QuotationAttachmentModel = {
  id: number;
  fileName: string;
}

function getAll(): Promise<PackageModel[]> {
  return axios.get(apiUrl);
}

function getMyPackages(): Promise<PackageModel[]> {
  return axios.get(`${apiUrl}/getMyPackages`);
}

function get(packageCode: string): Promise<PackageModel> {
  return axios.get(`${apiUrl}/getPackageDetail/${packageCode}`);
}

function getQuotation(packageCode: string): Promise<QuotationModel> {
  return axios.get(`${apiUrl}/getQuotation/${packageCode}`);
}

function createQuotation(model: CreateQuotationModel, packageCode: string): Promise<unknown> {
  const formData = new FormData();
  model.files.map((f) => {
    formData.append('Files', f, f.name);
    return true;
  });
  model.items.map((item, index) => {
    formData.append(`Items[${index}][ItemId]`, item.itemId);
    formData.append(`Items[${index}][Price]`, item.price);
    return true;
  });

  return axios.post(
    `${apiUrl}/createQuotation/${packageCode}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

const service = {
  getAll,
  getMyPackages,
  get,
  getQuotation,
  createQuotation,
};

export default service;
