import { IAppUser, LoginModel, ChangePasswordModel } from '../../services/account';

export type AuthState = {
  isLoading: boolean,
  isError: boolean,
  errorMessage: string,
  isVerifying: boolean,
  user: IAppUser,
}

export const initialState: AuthState = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  isVerifying: true,
  user: {
    code: '',
    username: '',
    fullName: '',
    isResetPassword: false,
  },
};

export type AuthStore = {
  state: AuthState;
  verify: (returnUrl?: string) => void;
  login: (model: LoginModel, returnUrl?: string) => void;
  changePassword: (model: ChangePasswordModel) => void;
  logout: () => void;
  clear: () => void;
}

export const defaultStore: AuthStore = {
  state: initialState,
  verify: (returnUrl?: string) => { console.log(returnUrl); },
  login: (model: LoginModel, returnUrl?: string) => { console.log(model, returnUrl); },
  changePassword: (model: ChangePasswordModel) => { console.log(model); },
  logout: () => { return; },
  clear: () => { return; },
};
