import { Dispatch } from 'react';
import history from '../../configs/history';
import accountService, {
  IAppUser,
  LoginModel,
  ChangePasswordModel,
} from '../../services/account';
import {
  Actions,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  LOGOUT,
  VERIFY_REQUEST,
  VERIFY_FAILURE,
  VERIFY_SUCCESS,
  authErrors,
} from './constant';

const verify = async (returnUrl = '/', dispatch: Dispatch<Actions>): Promise<void> => {
  function request(): Actions { return { type: VERIFY_REQUEST }; }
  function success(user: IAppUser): Actions {
    return {
      type: VERIFY_SUCCESS,
      payload: user,
    };
  }
  function fail(): Actions { return { type: VERIFY_FAILURE }; }

  dispatch(request());

  try {
    const data = await accountService.verify();
    dispatch(success(data));
    history.push(returnUrl);
  } catch {
    dispatch(fail());
  }
};

const login = async (
  model: LoginModel,
  returnUrl = '/',
  dispatch: Dispatch<Actions>,
): Promise<void> => {
  function request(): Actions { return { type: LOGIN_REQUEST }; }
  function fail(error: string): Actions { return { type: LOGIN_FAILURE, error }; }

  dispatch(request());

  try {
    await accountService.login(model);
    verify(returnUrl, dispatch);
  } catch {
    dispatch(fail(authErrors.LOGIN_FAIL));
  }
};

const changePassword = async (
  model: ChangePasswordModel,
  dispatch: Dispatch<Actions>,
): Promise<void> => {
  function request(): Actions { return { type: CHANGE_PASSWORD_REQUEST }; }
  function fail(error: string): Actions { return { type: CHANGE_PASSWORD_FAILURE, error }; }

  dispatch(request());

  if (model.newPassword !== model.confirmPassword) {
    dispatch(fail(authErrors.PASSWORD_NOT_MATCH));
  } else if (model.newPassword === model.oldPassword) {
    dispatch(fail(authErrors.PASSWORD_NOT_CHANGE));
  } else {
    try {
      await accountService.changePassword(model);
      verify('/', dispatch);
    } catch {
      dispatch(fail(authErrors.PASSWORD_CHANGE_FAILURE));
    }
  }
};

function logout(): Actions {
  accountService.logout();
  return { type: LOGOUT };
}

function clear(): Actions {
  return { type: LOGOUT };
}

const actions = {
  login,
  changePassword,
  logout,
  verify,
  clear,
};

export default actions;
