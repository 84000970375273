import axios from 'axios';

export type IAppUser = {
  code: string;
  username: string;
  fullName: string;
  isResetPassword: boolean;
}

export type LoginModel = {
  code: string;
  password: string;
  remember: boolean;
}

export type ChangePasswordModel = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type ResetPasswordModel = {
  token: string;
  newPassword: string;
}

export type RegisterModel = {
  code: string;
  password: string;
  email: string;
  address: string;
  phone: string;
  shortTextName: string;
  longTextName: string;
}

function login(model: LoginModel): Promise<unknown> {
  return axios.post('/api/account/login', model);
}

function changePassword(model: ChangePasswordModel): Promise<unknown> {
  return axios.post('api/account/changePassword', model);
}

function verify(): Promise<IAppUser> {
  return axios.get('/api/account/me');
}

function logout(): Promise<unknown> {
  return axios.post('/api/account/logout');
}

function createResetPassword(code: string): Promise<unknown> {
  return axios.post(`/api/account/createResetPassword/${code}`);
}

function resetPassword(model: ResetPasswordModel): Promise<unknown> {
  return axios.post('/api/account/resetPassword', model);
}

function register(model: RegisterModel): Promise<unknown> {
  return axios.post('/api/account/register', model);
}

const service = {
  login,
  changePassword,
  logout,
  verify,
  createResetPassword,
  resetPassword,
  register,
};

export default service;
