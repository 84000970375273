import axios from 'axios';

const apiUrl = (vendorCode: string) => `/api/vendors/${vendorCode}/addresses`;

export type CreateAddressModel = {
  addressType: number;
  data: string;
}

function create(vendorCode: string, model: CreateAddressModel): Promise<unknown> {
  return axios.post(apiUrl(vendorCode), model);
}

function remove(vendorCode: string, addressId: number): Promise<unknown> {
  return axios.delete(`${apiUrl(vendorCode)}/${addressId}`);
}

const service = {
  create,
  remove,
};

export default service;
