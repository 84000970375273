import React from 'react';
import {
	Grid,
	Typography,
} from '@material-ui/core';

import vendorService, { VendorDetailModel } from '../services/vendor';
import addressService from '../services/address';
import {
	Frame,
	IconButton,
	TextField,
} from '../components/ui-shared';
import { StatusContext, alertMessage } from '../stores/StatusStore';
import { LayoutContext } from '../components/Layout';
import { AuthContext } from '../stores/AuthStore';

const initialState: VendorDetailModel = {
	addresses: [],
	code: '',
	longTextName: '',
	shortTextName: '',
}

export default function Manage(): JSX.Element {
	const { showAlert, showBackdrop, hideBackdrop } = React.useContext(StatusContext);
	const { logout } = React.useContext(AuthContext);
	const { changeIndex } = React.useContext(LayoutContext);
	const [state, setState] = React.useState({
		address: '',
		email: '',
		phone: '',
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (addressType: number, data: string) => {
		showBackdrop();

		try {
			await addressService.create(vendor.code, {
				addressType: addressType,
				data: data
			});

			showAlert(alertMessage.ACTION_SUCCESS, 'success');
			setState({ address: '', email: '', phone: '' });
			refresh();
		}
		catch (error) {
			hideBackdrop();
			if (error.response.status === 400) {
				showAlert(error.response.data, 'error');
			}
			else {
				showAlert(alertMessage.ACTION_FAILURE, 'error');
			}
		}
	}

	const handleRemove = async (addressId: number) => {
		showBackdrop();

		try {
			await addressService.remove(vendor.code, addressId);

			showAlert(alertMessage.ACTION_SUCCESS, 'success');
			refresh();
		}
		catch (error) {
			hideBackdrop();
			if (error.response.status === 400) {
				showAlert(error.response.data, 'error');
			}
			else {
				showAlert(alertMessage.ACTION_FAILURE, 'error');
			}
		}
	}

	const [vendor, setVendor] = React.useState<VendorDetailModel>(initialState);

	const refresh = async () => {
		showBackdrop();
		try {
			setVendor(await vendorService.get());
			hideBackdrop();
		}
		catch {
			hideBackdrop();
			showAlert(alertMessage.FETCH_FAILURE, 'error');
		}
	}

	React.useEffect(() => {
		refresh();
		changeIndex(2);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container spacing={1} alignItems="stretch">
			<Grid item xs={12}>
				<Frame title="Thông tin tài khoản">
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="caption">Mã số thuế</Typography>
							<Typography variant="subtitle2">{vendor.code}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="caption">Tên nhà cung cấp</Typography>
							<Typography variant="subtitle2">{vendor.longTextName}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="caption">Tên rút gọn</Typography>
							<Typography variant="subtitle2">{vendor.shortTextName}</Typography>
						</Grid>
						<Grid xs={12} item container spacing={1}>
							<Grid item>
								<IconButton
									color="danger"
									text="Đăng xuất"
									icon="power-off"
									variant="contained"
									onClick={() => { logout(); }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Frame>
			</Grid>
			<Grid item xs={12}>
				<Frame title="Địa chỉ">
					<Grid container spacing={1}>
						{vendor.addresses.filter(x => x.addressType === 1).map((addr, index) => (
							<Grid key={index} container item xs={12} spacing={1}>
								<Grid item>
									<Typography variant="subtitle2">{addr.data}</Typography>
								</Grid>
								<Grid item>
									<IconButton
										color="danger"
										text="Xóa"
										icon="trash-alt"
										onClick={() => { handleRemove(addr.id) }}
									/>
								</Grid>
							</Grid>
						))}
						<Grid container item xs={12} spacing={1} alignItems="flex-end">
							<Grid item xs={12} md={6}>
								<TextField
									label="Nhập địa chỉ cần thêm mới"
									name="address"
									value={state.address}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item>
								<IconButton
									color="primary"
									text="Thêm địa chỉ"
									icon="plus"
									variant="contained"
									onClick={() => { handleSubmit(1, state.address); }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Frame>
			</Grid>
			<Grid item xs={12}>
				<Frame title="Email">
					<Grid container spacing={1}>
						{vendor.addresses.filter(x => x.addressType === 2).map((addr, index) => (
							<Grid key={index} container item xs={12} spacing={1}>
								<Grid item>
									<Typography variant="subtitle2">{addr.data}</Typography>
								</Grid>
								<Grid item>
									<IconButton
										color="danger"
										text="Xóa"
										icon="trash-alt"
										onClick={() => { handleRemove(addr.id) }}
									/>
								</Grid>
							</Grid>
						))}
						<Grid container item xs={12} spacing={1} alignItems="flex-end">
							<Grid item xs={12} md={6}>
								<TextField
									label="Nhập email cần thêm mới"
									name="email"
									value={state.email}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item>
								<IconButton
									color="primary"
									text="Thêm email"
									icon="plus"
									variant="contained"
									onClick={() => { handleSubmit(2, state.email); }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Frame>
			</Grid>
			<Grid item xs={12}>
				<Frame title="Điện thoại liên hệ">
					<Grid container spacing={1}>
						{vendor.addresses.filter(x => x.addressType === 3).map((addr, index) => (
							<Grid key={index} container item xs={12} spacing={1}>
								<Grid item>
									<Typography variant="subtitle2">{addr.data}</Typography>
								</Grid>
								<Grid item>
									<IconButton
										color="danger"
										text="Xóa"
										icon="trash-alt"
										onClick={() => { handleRemove(addr.id) }}
									/>
								</Grid>
							</Grid>
						))}
						<Grid container item xs={12} spacing={1} alignItems="flex-end">
							<Grid item xs={12} md={6}>
								<TextField
									label="Nhập số điện thoại cần thêm mới"
									name="phone"
									value={state.phone}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item>
								<IconButton
									color="primary"
									text="Thêm điện thoại liên hệ"
									icon="plus"
									variant="contained"
									onClick={() => { handleSubmit(3, state.phone); }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Frame>
			</Grid>
		</Grid>
	)
}
