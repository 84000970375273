import axios from 'axios';

const apiUrl = '/api/vendors';

export type VendorDetailModel = {
  code: string;
  longTextName: string;
  shortTextName: string;
  addresses: AddressModel[];
}

export type AddressModel = {
  id: number;
  addressType: number;
  data: string;
}

function get(): Promise<VendorDetailModel> {
  return axios.get(apiUrl);
}

const service = {
  get,
};

export default service;
