import React from 'react';
import { useHistory } from 'react-router-dom';
import { red, amber, blue } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { LayoutContext } from '../components/Layout';
import packageService, { PackageModel } from '../services/package';
import {
	IconButton,
	DataTable,
	DataColumnType,
} from '../components/ui-shared';
import { StatusContext, alertMessage } from '../stores/StatusStore';
import format from '../configs/format';

type Data = PackageModel & {
	beginText: string,
	endText: string,
	statusText: JSX.Element,
	action: JSX.Element,
}

type PackageStatusType = {
	text: string;
	icon: IconName;
	color: string;
}

const columns: DataColumnType<Data>[] = [
	{ id: 'code', label: 'Mã', numeric: false, sortable: true },
	{ id: 'name', label: 'Tên gói thầu', numeric: false, sortable: false },
	{ id: 'beginText', label: 'Mở thầu', numeric: false, sortable: true },
	{ id: 'endText', label: 'Đóng thầu', numeric: false, sortable: true },
	{ id: 'statusText', label: 'Trạng thái', numeric: false, sortable: true },
	{ id: 'action', label: '', numeric: false, sortable: false },
];

export default function Packages(): JSX.Element {
	const history = useHistory();
	const { changeIndex } = React.useContext(LayoutContext);
	const { showAlert, showBackdrop, hideBackdrop } = React.useContext(StatusContext);

	const [packages, setPackages] = React.useState<PackageModel[]>([]);

	const getStatusProps = (status: number): PackageStatusType => {
		switch (status) {
			case 0:
				return { text: 'Mới', icon: 'star', color: red[700] };
			case 1:
				return { text: 'Đang mở', icon: 'spinner', color: amber[700] };
			case 2:
				return { text: 'Đã đóng', icon: 'check-circle', color: blue[700] };
			default:
				return { text: 'Không xác định', icon: 'exclamation-circle', color: '' };
		}
	}

	const actionsButton: JSX.Element[] = [
		<IconButton
			key={1}
			tooltip="Làm mới danh sách"
			text="Làm mới"
			icon="sync-alt"
			color="primary"
			onClick={() => { refresh(); }}
		/>,
	];

	const renderData = (data: PackageModel[]): Data[] => {
		return data.map(item => {
			const row: Data = {
				...item,
				beginText: format.formatDate(new Date(item.begin), 'hh:mm - dd/MM/yyyy'),
				endText: format.formatDate(new Date(item.end), 'hh:mm - dd/MM/yyyy'),
				statusText: (
					<>
						<FontAwesomeIcon
							icon={getStatusProps(item.status).icon}
							color={getStatusProps(item.status).color}
						/>
						{` ${getStatusProps(item.status).text}`}
					</>
				),
				action: (
					<>
						<IconButton
							tooltip="Xem chi tiết gói thầu"
							text="Xem"
							icon="info-circle"
							color="success"
							onClick={() => { history.push(`/packages/${item.code}`) }}
						/>
					</>
				)
			}

			return row;
		});
	}

	const refresh = async () => {
		showBackdrop();
		try {
			setPackages(await packageService.getMyPackages());
			hideBackdrop();
		}
		catch {
			hideBackdrop();
			showAlert(alertMessage.FETCH_FAILURE, 'error');
		}
	}

	React.useEffect(() => {
		changeIndex(1);
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DataTable
			title="Gói thầu tham gia"
			columns={columns}
			data={renderData(packages)}
			actions={actionsButton}
			initialOrderBy="begin"
		/>
	)
}