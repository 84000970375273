import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  Input,
  InputAdornment,
  IconButton,
  Hidden,
  Grid
} from '@material-ui/core'

import Icon from '../Icon'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  toolbar: {
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(4.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  title: {
    flex: '1 1 100%',
    fontSize: '1.1rem'
  },
  actions: {
    color: theme.palette.text.primary,
    display: 'flex',
    paddingRight: theme.spacing(2)
  },
  input: {
    width: 200
  }
}))

type DataTableToolbarProps = {
  title: string
  keyWord?: string
  actions?: JSX.Element[]
  onChangeKeyWord?: (value: string) => void
}

export default function DataTableToolbar({
  title,
  keyWord = '',
  actions = [],
  onChangeKeyWord = (value: string) => {
    return value
  }
}: DataTableToolbarProps): JSX.Element {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeKeyWord(event.target.value)
  }

  const handleClear = () => {
    onChangeKeyWord('')
  }

  return (
    <div>
      <Hidden smDown implementation='css'>
        <Toolbar disableGutters className={classes.toolbar}>
          <Typography className={classes.title} variant='h6' component='div'>
            {title}
          </Typography>
          <div className={classes.actions}>
            {actions.map((action) => {
              return action
            })}
          </div>
          <div>
            <Input
              className={classes.input}
              onChange={handleChange}
              value={keyWord}
              startAdornment={
                <InputAdornment position='start'>
                  <Icon color='primary' icon='search' />
                </InputAdornment>
              }
              endAdornment={
                keyWord !== '' && (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onClick={handleClear}>
                      <Icon color='secondary' icon='times' />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </div>
        </Toolbar>
      </Hidden>
      <Hidden mdUp implementation='css'>
        <Toolbar style={{ height: 'auto', paddingBottom: '4px' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography noWrap variant='h6' component='div'>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.actions}>
              {actions.map((action) => {
                return action
              })}
            </Grid>
            <Grid item xs={8}>
              <Input
                className={classes.input}
                onChange={handleChange}
                value={keyWord}
                startAdornment={
                  <InputAdornment position='start'>
                    <Icon color='primary' icon='search' />
                  </InputAdornment>
                }
                endAdornment={
                  keyWord !== '' && (
                    <InputAdornment position='end'>
                      <IconButton edge='end' onClick={handleClear}>
                        <Icon color='secondary' icon='times' />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Hidden>
    </div>
  )
}
