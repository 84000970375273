import React from 'react';

import { LayoutContext } from '../components/Layout';

export default function Help(): JSX.Element {
  const { changeIndex } = React.useContext(LayoutContext);

  React.useEffect(() => {
    changeIndex(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>Help</div>
  )
}