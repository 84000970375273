import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Grid,
	Typography,
	Paper,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { red, amber, blue, green } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { LayoutContext } from '../components/Layout';
import format from '../configs/format';
import excelReader, { ExcelColumnType } from '../configs/excelReader';
import packageService, { PackageModel, ItemModel, CreateQuotationModel, QuotationModel } from '../services/package';
import {
	Frame,
	TagInfo,
	DataTable,
	DataColumnType,
	DownloadButton,
	TextField,
	IconButton,
	FormDialog,
} from '../components/ui-shared';
import { StatusContext, alertMessage } from '../stores/StatusStore';

type Data = ItemModel & {
	infoCol: JSX.Element,
	quantityCol: JSX.Element,
	noteCol: JSX.Element,
	deliveryCol: JSX.Element,
	priceCol: JSX.Element,
}

type ExcelData = {
	code: string;
	spec: string;
	note: string;
	price: number;
}

const excelColumns: ExcelColumnType<ExcelData>[] = [
	{ name: 'code', col: 1 },
	{ name: 'spec', col: 3 },
	{ name: 'note', col: 4 },
	{ name: 'price', col: 11 },
]

const columns: DataColumnType<Data>[] = [
	{ id: 'no', label: 'STT', numeric: false, sortable: false },
	{ id: 'infoCol', label: 'Hạng mục', numeric: false, sortable: false },
	{ id: 'quantityCol', label: 'Số lượng', numeric: false, sortable: false },
	{ id: 'noteCol', label: 'Ghi chú', numeric: false, sortable: false },
	{ id: 'deliveryCol', label: 'Thời gian giao hàng', numeric: false, sortable: false },
	{ id: 'priceCol', label: 'Đơn giá', numeric: false, sortable: false },
];

const initialState: PackageModel = {
	attachments: [],
	begin: new Date(),
	code: '',
	status: 0,
	description: '',
	end: new Date(),
	items: [],
	name: '',
}

const initialQuotationState: QuotationModel = {
	submitted: false,
	items: [],
	attachments: [],
}

type PackageStatusType = {
	text: string;
	icon: IconName;
	color: string;
}

export default function PackageDetail(): JSX.Element {
	const { packageCode }: never = useParams();
	const { showAlert, showBackdrop, hideBackdrop } = React.useContext(StatusContext);
	const { changeIndex } = React.useContext(LayoutContext);

	const [packageDetail, setPackageDetail] = React.useState<PackageModel>(initialState);
	const [quotation, setQuotation] = React.useState<QuotationModel>(initialQuotationState);

	const [files, setFiles] = React.useState<File[] | undefined>();
	const [showUploadForm, setShowUploadForm] = React.useState(false);

	const handleChangeFile = (files: File[]) => {
		if (Array.isArray(files)) {
			setFiles(files);
		}
	};

	const handleChangePrice = (itemId: number, value: string) => {
		setPackageDetail({
			...packageDetail,
			items: packageDetail.items.map(x => {
				if (x.id === itemId) {
					x.price = value;
					return x;
				}

				return x;
			})
		})
	}

	const handleSubmitUploadForm = async (files: File[]) => {
		if (Array.isArray(files)) {
			if (files.length > 0) {
				const file = files[0];
				setShowUploadForm(false);
				showBackdrop();
				try {
					const excelData = await excelReader.readToArray<ExcelData>(
						file,
						excelColumns,
						7,
						{ code: '', spec: '', note: '', price: 0 }
					);

					const items = packageDetail.items.map(item => {
						const newItem = item;
						const exist = excelData.find(x =>
							x.code === item.code &&
							(item.note === null || x.note === item.note) &&
							(item.spec === null || x.spec === item.spec)
						);
						if (exist !== undefined) {
							newItem.price = exist.price.toFixed(0);
						}

						return newItem;
					});

					setPackageDetail({
						...packageDetail,
						items: items,
					});

					showAlert(alertMessage.ACTION_SUCCESS, 'success');
					hideBackdrop();
				}
				catch {
					hideBackdrop();
					showAlert('File sai mẫu! Vui lòng kiểm tra lại!', 'error');
				}
			}
		}
	};

	const handleSubmitQuotation = async () => {
		const checkPrice = packageDetail.items.find(x => parseFloat(x.price) <= 0 || Number.isNaN(parseFloat(x.price)));
		if (checkPrice !== undefined || files === undefined || files.length === 0) {
			showAlert('Vui lòng nhập đủ dữ liệu trước khi nộp chào giá!', 'error');
		}
		else {
			const model: CreateQuotationModel = {
				files: files,
				items: packageDetail.items.map(x => ({ itemId: x.id.toString(), price: x.price })),
			}

			showBackdrop();
			try {
				await packageService.createQuotation(model, packageDetail.code);

				showAlert(alertMessage.ACTION_SUCCESS, 'success');
				refresh();
			}
			catch (error) {
				hideBackdrop();
				if (error.response.status === 400) {
					showAlert(error.response.data, 'error');
				}
				else {
					showAlert(alertMessage.ACTION_FAILURE, 'error');
				}
			}
		}
	}

	const refresh = async () => {
		showBackdrop();
		try {
			const data = await Promise.all([packageService.get(packageCode), packageService.getQuotation(packageCode)]);
			setPackageDetail({
				...data[0],
				items: data[0].items.map(x => {
					if (data[1].submitted) {
						const item = data[1].items.find(y => y.itemId === x.id);
						if (item !== undefined) {
							return ({ ...x, price: format.formatMoney(item.price, 3) });
						}
						else {
							return ({ ...x, price: '0' });
						}
					}
					else {
						return ({ ...x, price: '0' });
					}
				}),
			});

			setQuotation(data[1]);

			hideBackdrop();
		}
		catch {
			hideBackdrop();
			setPackageDetail(initialState);
			showAlert(alertMessage.FETCH_FAILURE, 'error');
		}
	}

	const handleCloseUploadForm = () => {
		setShowUploadForm(false);
	};

	const handleOpenUploadForm = () => {
		setShowUploadForm(true);
	};

	const actionsButton: JSX.Element[] = [
		packageDetail.status !== 2 ? <DownloadButton
			key={1}
			label="Mẫu chào giá"
			filename={`${packageCode}_Quotation Form.xlsx`}
			url={`/api/packages/getQuotationForm/${packageDetail.code}`}
		/> : <React.Fragment key={11}></React.Fragment>,
		packageDetail.status === 1 && !quotation.submitted ? <IconButton
			key={2}
			tooltip="Tạo từ file excel"
			text="Excel"
			icon="file-excel"
			color="primary"
			onClick={handleOpenUploadForm}
		/> : <React.Fragment key={12}></React.Fragment>,
	];

	const renderData = (data: ItemModel[]): Data[] => {
		return data.map(item => {
			const row: Data = {
				...item,
				infoCol: (
					<div>
						<Typography variant="subtitle2">{item.description}</Typography>
						<Typography variant="caption">{item.code}</Typography>
					</div>
				),
				quantityCol: (
					<div>
						<Typography variant="subtitle2">
							{`${format.formatMoney(item.quantity, 3)} ${item.unit}`}
						</Typography>
						<Typography variant="caption">
							{`${format.formatMoney(item.optionQuantity, 3)} ${item.optionUnit}`}
						</Typography>
					</div>
				),
				noteCol: (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{item.spec !== null && <Typography variant="caption">{`Spec: ${item.spec}`}</Typography>}
						{item.spec !== null && <Typography variant="caption">{`Ghi chú: ${item.note}`}</Typography>}
					</div>
				),
				deliveryCol: (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant="caption">
							{`Từ: ${format.formatDate(new Date(item.begin), 'dd/MM/yyyy')}`}
						</Typography>
						<Typography variant="caption">
							{`Đến: ${format.formatDate(new Date(item.end), 'dd/MM/yyyy')}`}
						</Typography>
					</div>
				),
				priceCol: (quotation.submitted || packageDetail.status !== 1) ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant="subtitle2">
							{item.price}
						</Typography>
						<Typography variant="caption">
							{`VND/1 ${item.unit}`}
						</Typography>
					</div>
				) : (
					<TextField
						label={`VND/1 ${item.unit}`}
						value={item.price}
						type="number"
						onChange={(event) => { handleChangePrice(item.id, event.target.value) }
						}
					/>
				)
			}

			return row;
		});
	}

	const getStatusProps = (status: number): PackageStatusType => {
		switch (status) {
			case 0:
				return { text: 'Mới', icon: 'star', color: red[700] };
			case 1:
				return { text: 'Đang mở', icon: 'spinner', color: amber[700] };
			case 2:
				return { text: 'Đã đóng', icon: 'check-circle', color: blue[700] };
			default:
				return { text: 'Không xác định', icon: 'exclamation-circle', color: '' };
		}
	}

	React.useEffect(() => {
		refresh();
		changeIndex(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageCode]);

	return (
		<>
			<FormDialog
				open={showUploadForm}
				onClose={handleCloseUploadForm}
				title="Tải tệp excel"
			>
				<DropzoneArea
					acceptedFiles={[
						'.xls',
						'.xlsx',
					]}
					showPreviews={false}
					showFileNames
					filesLimit={1}
					maxFileSize={25000000}
					useChipsForPreview
					dropzoneText="Kéo thả file vào đây để tải lên"
					onChange={handleSubmitUploadForm}
					showAlerts={false}
				/>
			</FormDialog>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Frame title="Thông tin chung">
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<Typography variant="caption">Mã số gói thầu</Typography>
								<Typography variant="subtitle2">
									{`${packageDetail.code} - Trạng thái: `}
									<FontAwesomeIcon
										icon={getStatusProps(packageDetail.status).icon}
										color={getStatusProps(packageDetail.status).color}
									/>
									{` ${getStatusProps(packageDetail.status).text}`}
								</Typography>
							</Grid>
							<TagInfo title="Tên gói thầu" content={packageDetail.name} />
							<TagInfo
								title="Thời gian mở thầu"
								content={format.formatDate(new Date(packageDetail.begin), 'hh:mm dd/MM/yyyy')}
							/>
							<TagInfo
								title="Thời gian đóng thầu"
								content={format.formatDate(new Date(packageDetail.end), 'hh:mm dd/MM/yyyy')}
							/>
							<Grid item xs={12}>
								<Typography variant="caption">Mô tả</Typography>
								<Paper style={{ padding: '8px' }} variant="outlined">
									<div dangerouslySetInnerHTML={{ __html: packageDetail.description }}></div>
								</Paper>
							</Grid>
							<Grid xs={12} item>
								<Typography variant="caption">Tài liệu đính kèm</Typography>
								{packageDetail.attachments.map((a, index) => (
									<Grid key={index} container spacing={1}>
										<Grid item>
											<FontAwesomeIcon icon={{ iconName: 'check-square', prefix: 'far' }} />
										</Grid>
										<Grid item>
											<Typography variant="subtitle2">{a.fileName}</Typography>
										</Grid>
										<Grid item>
											<DownloadButton
												filename={a.fileName}
												url={`/api/packages/${packageDetail.code}/attachments/getPackageFile/${a.id}`}
											/>
										</Grid>
									</Grid>
								))}
							</Grid>
							<Grid xs={12} item>
								{quotation.submitted ? (
									<Typography variant="subtitle2">
										<FontAwesomeIcon
											icon="check-circle"
											color={green[700]}
										/>
										{` Đã nộp chào giá`}
									</Typography>
								) : packageDetail.status !== 1 ? (
									<Typography variant="subtitle2">
										<FontAwesomeIcon
											icon="times-circle"
											color={red[700]}
										/>
										{` Chưa nộp chào giá`}
									</Typography>
								) : (
									<IconButton
										text="Nộp chào giá"
										icon="paper-plane"
										color="success"
										variant="contained"
										onClick={() => { handleSubmitQuotation(); }}
									/>
								)}
							</Grid>
						</Grid>
					</Frame>
				</Grid>
				<Grid item xs={12}>
					<DataTable
						title="Danh sách vật tư, dịch vụ"
						columns={columns}
						data={renderData(packageDetail.items)}
						actions={actionsButton}
						initialOrderBy="no"
					/>
				</Grid>
				<Grid item xs={12}>
					<Frame title="Tài liệu đính kèm chào giá">
						{(quotation.submitted || packageDetail.status !== 1) ?
							quotation.attachments.map((a, index) => (
								<Grid key={index} container spacing={1}>
									<Grid item>
										<FontAwesomeIcon icon={{ iconName: 'check-square', prefix: 'far' }} />
									</Grid>
									<Grid item>
										<Typography variant="subtitle2">{a.fileName}</Typography>
									</Grid>
									<Grid item>
										<DownloadButton
											filename={a.fileName}
											url={`/api/packages/${packageDetail.code}/attachments/getQuotationFile/${a.id}`}
										/>
									</Grid>
								</Grid>
							)) : (
								<DropzoneArea
									showPreviews={false}
									acceptedFiles={[
										'.doc',
										'.docx',
										'.xls',
										'.xlsx',
										'.pdf',
										'.rar',
										'.zip',
									]}
									showFileNames
									filesLimit={10}
									maxFileSize={25000000}
									useChipsForPreview
									dropzoneText="Kéo thả file vào đây để tải lên"
									onChange={handleChangeFile}
									showAlerts={false}
								/>
							)}
					</Frame>
				</Grid>
			</Grid>
		</>
	)
}
