import React from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';

import {
  TextField,
  IconButton,
} from '../components/ui-shared';

import { LayoutContext } from '../components/Layout';
import accountService, { RegisterModel } from '../services/account';

type FormState = RegisterModel & {
  confirmPassword: string;
}

const initialState: FormState = {
  address: '',
  code: '',
  email: '',
  longTextName: '',
  password: '',
  confirmPassword: '',
  phone: '',
  shortTextName: '',
}

export default function Register(): JSX.Element {
  const { changeIndex } = React.useContext(LayoutContext);
  const [model, setModel] = React.useState<FormState>(initialState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel({ ...model, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    setIsError(false);
    setErrorMessage('');
    setIsSuccess(false);
    setIsLoading(false);
    if (model.address === '' ||
      model.code === '' ||
      model.confirmPassword === '' ||
      model.email === '' ||
      model.longTextName === '' ||
      model.password === '' ||
      model.phone === '' ||
      model.shortTextName === '') {
      setIsError(true);
      setErrorMessage('Vui lòng điền đầy đủ các thông tin trước khi gửi đăng ký!');
      return;
    }

    if (model.password !== model.confirmPassword) {
      setIsError(true);
      setErrorMessage('Xác nhận mật khẩu không chính xác!');
      return;
    }

    setIsLoading(true);
    try {
      const data: RegisterModel = {
        address: model.address,
        code: model.code,
        email: model.email,
        longTextName: model.longTextName,
        password: model.password,
        phone: model.phone,
        shortTextName: model.shortTextName,
      }
      await accountService.register(data);
      setIsLoading(false);
      setIsSuccess(true);
    }
    catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(error.response.data);
    }
  };

  React.useEffect(() => {
    changeIndex(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item container spacing={1} xs={6}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Đăng ký nhà cung cấp
          </Typography>
          {isSuccess && (
            <Typography variant="body1">
              Đăng ký thành công! Tài khoản của bạn sẽ được xem xét phê duyệt trong thời gian sớm nhất có thể. Chúng tôi sẽ thông báo đến bạn qua email đăng ký khi có kết quả phê duyệt.
            </Typography>
          )}
        </Grid>
        {!isSuccess && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                label="Mã số thuế"
                name="code"
                value={model.code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Mật khẩu"
                type="password"
                value={model.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="confirmPassword"
                label="Xác nhận mật khẩu"
                type="password"
                value={model.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="address"
                label="Địa chỉ"
                type="text"
                value={model.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="email"
                label="Email"
                type="email"
                value={model.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="phone"
                label="Điện thoại liên hệ"
                type="phone"
                value={model.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="longTextName"
                label="Tên nhà cung cấp"
                type="text"
                value={model.longTextName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="shortTextName"
                label="Tên rút gọn"
                type="text"
                value={model.shortTextName}
                onChange={handleChange}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              {isError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <IconButton
                  variant="contained"
                  color="primary"
                  text="Đăng ký"
                  icon="user-plus"
                  onClick={handleSubmit}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
