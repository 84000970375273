import React, { createContext } from 'react';
import reducer from './reducers';
import { AuthStore, initialState, defaultStore } from './types';
import action from './actions';
import { LoginModel, ChangePasswordModel } from '../../services/account';

type AuthProviderProps = {
  children: JSX.Element | JSX.Element[];
}

export const AuthContext = createContext(defaultStore);

export default function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const verify = (returnUrl = '/') => {
    action.verify(returnUrl, dispatch);
  };

  const login = (model: LoginModel, returnUrl = '/') => {
    action.login(model, returnUrl, dispatch);
  };

  const changePassword = (model: ChangePasswordModel) => {
    action.changePassword(model, dispatch);
  };

  const logout = () => {
    dispatch(action.logout());
  };

  const clear = () => {
    dispatch(action.clear());
  };

  const store: AuthStore = {
    state,
    verify,
    login,
    changePassword,
    logout,
    clear,
  };

  return (
    <AuthContext.Provider value={store}>
      {children}
    </AuthContext.Provider>
  );
}
