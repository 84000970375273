import React, { createContext } from 'react';
import { StatusStore, AlertModel, BackdropModel, defaultStore } from './types';
import { AlertType } from '../../components/ui-shared';

type StatusProviderProps = {
  children: JSX.Element | JSX.Element[];
}

export const StatusContext = createContext(defaultStore);

export default function StatusProvider({ children }: StatusProviderProps): JSX.Element {
  const [alert, setAlert] = React.useState<AlertModel>({
    show: false,
    severity: 'success',
    text: '',
  });

  const [backdrop, setBackdrop] = React.useState<BackdropModel>({
    show: false,
  })

  const showAlert = (text: string, severity: AlertType) => {
    setAlert({
      show: true,
      text: text,
      severity: severity,
    });
  };

  const hideAlert = () => {
    setAlert({
      ...alert,
      show: false,
    });
  }

  const showBackdrop = () => {
    setBackdrop({
      show: true,
    });
  };

  const hideBackdrop = () => {
    setBackdrop({
      show: false,
    });
  }

  const store: StatusStore = {
    alert,
    backdrop,
    showAlert,
    hideAlert,
    showBackdrop,
    hideBackdrop,
  };

  return (
    <StatusContext.Provider value={store}>
      {children}
    </StatusContext.Provider>
  );
}
