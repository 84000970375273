import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { AuthContext } from './stores/AuthStore';
import { StatusContext } from './stores/StatusStore';
import { Verifying, Alert, Backdrop, AuthRoute } from './components/ui-shared';

import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordRequest from './pages/ResetPasswordRequest';

import Home from './pages/Home';
import Help from './pages/Help';

import Packages from './pages/Packages';
import Manage from './pages/Manage';

import PackageDetail from './pages/PackageDetail';

export default function App(): JSX.Element {
  const location = useLocation();
  const { state, verify } = React.useContext(AuthContext);
  const { alert, backdrop, hideAlert } = React.useContext(StatusContext);

  React.useEffect(() => {
    verify(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    window !== top ? <div>
      Đây là trang web giả mạo.
      Ấn vào đường dẫn bên dưới để về trang chính thưc của chúng tôi.
      <a href="https://ebidding.ecoba.com.vn" target="blank">emis.ecoba.com.vn</a>
    </div> :
      state.isVerifying
        ? <Verifying />
        : (
          <Layout >
            <>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/reset-password/:resetPasswordToken" component={ResetPassword} />
                <Route path="/reset-password-request" component={ResetPasswordRequest} />

                <Route exact path="/" component={Home} />
                <Route path="/help" component={Help} />

                <AuthRoute path="/my-package-list" component={Packages} />
                <AuthRoute path="/manage" component={Manage} />

                <AuthRoute path="/packages/:packageCode" component={PackageDetail} />
              </Switch>
              <Alert
                text={alert.text}
                severity={alert.severity}
                show={alert.show}
                onClose={hideAlert}
              />
              <Backdrop show={backdrop.show} />
            </>
          </Layout >

        )
  );
}
