import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  TableFooter,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Hidden,
  Grid,
  Typography
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

// eslint-disable-next-line no-unused-vars
import { DataColumnType } from './types'
// eslint-disable-next-line no-unused-vars
import DataTableHead, { Order } from './DataTableHead'
import DataTableToolbar from './DataTableToolbar'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function filterByKeyWord<T>(array: T[], keyWord: string): T[] {
  return array.filter((item) => {
    let str = ''
    Object.values(item).forEach((value) => {
      if (typeof value === 'string') {
        str += `${value}`
      }
    })
    if (str.toUpperCase().includes(keyWord.toUpperCase())) {
      return true
    }
    return false
  })
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  pagination: {
    '& > *': {
      marginTop: theme.spacing(2)
    }
  }
}))

type DataTableProps<T> = {
  data: T[]
  columns: DataColumnType<T>[]
  title: string
  actions?: JSX.Element[]
  rowsPerPage?: number
  initialOrderBy: keyof T
}

export default function DataTable<T>({
  data,
  columns,
  title,
  actions = [],
  rowsPerPage = 10,
  initialOrderBy
}: DataTableProps<T>): JSX.Element {
  const classes = useStyles()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof T>(initialOrderBy)
  const [page, setPage] = React.useState<number>(0)
  const [keyWord, setKeyWord] = React.useState<string>('')

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1)
  }

  const rows = filterByKeyWord(
    stableSort(data, getComparator(order, orderBy)),
    keyWord
  )

  return (
    <Paper className={classes.paper}>
      <DataTableToolbar
        title={title}
        keyWord={keyWord}
        onChangeKeyWord={setKeyWord}
        actions={actions}
      />
      <Divider />
      <Hidden smDown implementation='css'>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='small'
            aria-label='data table'
          >
            <DataTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <TableRow hover key={i}>
                    {columns.map((col, j) => (
                      <TableCell key={j} align={col.numeric ? 'right' : 'left'}>
                        {row[col.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdUp implementation='css'>
        <Grid container spacing={1} style={{ padding: '8px' }}>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => (
              <Grid item xs={12} key={i}>
                {columns.map((col, j) => (
                  <div key={j}>
                    <Typography variant='caption'>{col.label}</Typography>
                    <Typography variant='subtitle2'>{row[col.id]}</Typography>
                  </div>
                ))}
              </Grid>
            ))}
        </Grid>
      </Hidden>
      <TableFooter className={classes.footer} component='div'>
        <div className={classes.pagination}>
          <Pagination
            count={Math.ceil(rows.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            shape='rounded'
          />
        </div>
      </TableFooter>
    </Paper>
  )
}
