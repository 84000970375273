import {
  AlertType,
} from '../../components/ui-shared';

export type AlertModel = {
  show: boolean;
  text: string;
  severity: AlertType;
}

export type BackdropModel = {
  show: boolean;
}

export type StatusStore = {
  alert: AlertModel;
  backdrop: BackdropModel;
  showAlert: (text: string, severity: AlertType) => void;
  hideAlert: () => void;
  showBackdrop: () => void;
  hideBackdrop: () => void;
}

export const defaultStore: StatusStore = {
  alert: { show: false, text: '', severity: 'success' },
  backdrop: { show: false },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showAlert: (text, severity) => { return; },
  hideAlert: () => { return; },
  showBackdrop: () => { return; },
  hideBackdrop: () => { return; },
};