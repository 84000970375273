import React from 'react';
import {
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import {
  TextField,
  IconButton,
} from '../components/ui-shared';

import accountServices from '../services/account';

export default function ResetPasswordRequest(): JSX.Element {
  const [vendorCode, setVendorCode] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleSubmit = async () => {
    setIsError(false);
    setErrorMessage('');
    if (vendorCode === '') {
      setIsError(true);
      setErrorMessage('Vui lòng nhập mã số thuế cần đặt lại mật khẩu!');
    }
    else {
      setIsLoading(true);
      try {
        await accountServices.createResetPassword(vendorCode);
        setIsSuccess(true);
        setIsLoading(false);
      }
      catch {
        setIsError(true);
        setErrorMessage('Không thể tạo yêu cầu đặt lại mật khẩu. Vui lòng thử lại sau!');
        setIsLoading(false);
      }
    }
  }

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Đặt lại mật khẩu
        </Typography>
          {isSuccess && (
            <Typography variant="body1">
              Yêu cầu đặt lại mật khẩu đã gửi đến email đăng ký. Vui lòng truy cập và làm theo hướng dẫn để cập nhật lại mật khẩu.
            </Typography>
          )}
        </Grid>
        {!isSuccess && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                label="Mã số thuế"
                name="vendorCode"
                value={vendorCode}
                onChange={(event) => { setVendorCode(event.target.value); }}
              />
            </Grid>
            {isLoading
              ? <Grid item xs={12}><LinearProgress /></Grid>
              : (
                <Grid container item xs={12} spacing={2}>
                  {isError && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="subtitle2">
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      text="Đặt lại mật khẩu"
                      icon="sync-alt"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
