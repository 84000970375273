import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
import { DataColumnType } from './types'

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

export type Order = 'asc' | 'desc'

type DataTableHeadProps<T> = {
  order: Order
  orderBy: keyof T
  columns: DataColumnType<T>[]
  onRequestSort: (property: keyof T) => void
}

export default function DataTableHead<T>({
  order,
  orderBy,
  columns,
  onRequestSort
}: DataTableHeadProps<T>): JSX.Element {
  const classes = useStyles()

  const createSortHandler = (property: keyof T) => () => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) =>
          col.sortable ? (
            <TableCell
              key={i}
              align={col.numeric ? 'right' : 'left'}
              sortDirection={orderBy === col.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === col.id}
                direction={orderBy === col.id ? order : 'asc'}
                onClick={createSortHandler(col.id)}
              >
                {col.label}
                {orderBy === col.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={i} align={col.numeric ? 'right' : 'left'}>
              {col.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}
