import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { AuthContext } from '../../stores/AuthStore';

type AuthenticationRouteProps = {
  exact?: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
}

export default function AuthRoute({
  exact = false,
  path,
  component: Component,
}: AuthenticationRouteProps): JSX.Element {
  const location = useLocation();
  const user = React.useContext(AuthContext).state.user;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        user.username !== ''
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
      )}
    />
  );
}
