import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  LinearProgress,
  Grid,
  Link as LinkMUI,
} from '@material-ui/core';

import {
  TextField,
  Checkbox,
  IconButton,
} from '../components/ui-shared';

import { LayoutContext } from '../components/Layout';
import { AuthContext } from '../stores/AuthStore';
import { LoginModel } from '../services/account';

type CustomStates = {
  from: string;
} | null | undefined;

export default function Login(): JSX.Element {
  const location = useLocation();
  const { state, login } = React.useContext(AuthContext);
  const { changeIndex } = React.useContext(LayoutContext);

  const [model, setModel] = React.useState<LoginModel>({
    code: '',
    password: '',
    remember: false,
  });

  const locationState = location.state as CustomStates;

  const returnUrl = locationState !== undefined && locationState !== null ? locationState.from : '/';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel({ ...model, [event.target.name]: event.target.value });
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setModel({ ...model, remember: checked });
  };

  const handleSubmit = () => {
    const { code, password } = model;
    if (code && password) {
      const data: LoginModel = {
        code: model.code,
        password: model.password,
        remember: model.remember,
      }
      login(data, returnUrl);
    }
  };

  React.useEffect(() => {
    changeIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item container xs={12} md={4} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Đăng nhập
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Mã số thuế"
            name="code"
            value={model.code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="password"
            label="Mật khẩu"
            type="password"
            value={model.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            name="remember"
            color="primary"
            label="Ghi nhớ?"
            onChange={handleChecked}
            checked={model.remember}
          />
        </Grid>
        {state.isLoading
          ? <Grid item xs={12}><LinearProgress /></Grid>
          : (
            <Grid container item xs={12} spacing={2}>
              {state.isError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {state.errorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <IconButton
                  variant="contained"
                  color="primary"
                  text="Đăng nhập"
                  icon="sign-in-alt"
                  onClick={handleSubmit}
                />
              </Grid>
              <Grid item xs={12}>
                <LinkMUI component={Link} to="/reset-password-request" variant="body2">
                  Quên mật khẩu?
              </LinkMUI>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
